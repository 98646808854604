import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'

import PlanDetails, { PlanDetailPropTypes } from '../plan-details'
import VoucherMessages from '../voucher-messages'
import { useStyles } from './payment-form.styles'
import { usePriceChangeAlertFlag } from '../../../../hooks/usePriceChangeAlert'
import { usePlanChangeFlag } from '../../../../hooks/usePlanChange'
import PaymentPriceChangeAlert from '../../../shared/payment-price-change-alert/payment-price-change-alert'
import { paymentAlertMessage } from '../../../shared/subscription-constants'

const getE2EAttribute = (isAnnuallyPlan, isTvodPlan) => {
  if (isAnnuallyPlan) {
    return 'signup-annual-iframe'
  }
  if (isTvodPlan) {
    return 'signup-tvod-iframe'
  }
  return 'signup-monthly-iframe'
}

/**
 * Renders the supplied plan details and Evergent iframe
 * for the user to add their payment information.
 */
const PaymentForm = ({
  copy,
  detail,
  iframeRef,
  iframeUrl,
  iframeClassName,
  promotionRejected,
  showVoucherMessage,
  isTvodPlan,
  isAnnuallyPlan,
  isStandardPlan
}) => {
  const styles = useStyles()
  const attribute = getE2EAttribute(isAnnuallyPlan, isTvodPlan)
  const showPriceChangeAlert = usePriceChangeAlertFlag()
  const getPlanDetails = usePlanChangeFlag()
  const paymentCopy = getPlanDetails?.annual.paymentCopy

  return (
    <div className={styles.container}>
      <p className={styles.copy}>
        {copy}
      </p>

      <p className={classNames(styles.copy, styles.alertMessage)}>
        {paymentAlertMessage}
      </p>

      {!isTvodPlan && (
        <PlanDetails detail={detail} promotionRejected={promotionRejected} />
      )}

      {showPriceChangeAlert && <PaymentPriceChangeAlert isStandardPlan={isStandardPlan} isAnnuallyPlan={isAnnuallyPlan} />}

      {isAnnuallyPlan && (
        <div
          className={styles.annualInfo}
          data-e2e="payment-form-annual-subscription-text"
        >
          {paymentCopy}
        </div>
      )}

      {showVoucherMessage && <VoucherMessages />}

      <div className={styles.divider} />

      <iframe
        ref={iframeRef}
        src={iframeUrl}
        className={classNames(styles.iframe, iframeClassName)}
        data-e2e={attribute}
      />

      {isTvodPlan && (
        <div className={styles.tvodOnholdInfo}>
          A $1 hold transaction is used to authenticate your account, and will
          be refunded automatically.
        </div>
      )}
    </div>
  )
}

PaymentForm.propTypes = {
  copy: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]).isRequired,
  detail: PlanDetailPropTypes,
  iframeRef: PropTypes.func.isRequired,
  iframeUrl: PropTypes.string.isRequired,
  promotionRejected: PropTypes.bool.isRequired,
  showVoucherMessage: PropTypes.bool.isRequired,
  isTvodPlan: PropTypes.bool,
  isAnnuallyPlan: PropTypes.bool,
  isStandardPlan: PropTypes.bool,
  iframeClassName: PropTypes.string
}

export default PaymentForm
